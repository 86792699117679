import ChartSwitchPanel from 'components/Charts/ChartSwitchPanel';
import ChartTile from 'components/Charts/ChartTile';
import FeedUse from 'components/Charts/FeedUse/Chart';
import FeedUseByType, {
  ChartSettings as FeedUseByTypeSettings
} from 'components/Charts/FeedUseByType/Chart';
import FeedUseByTypeController from 'components/Charts/FeedUseByType/Controller';
import SFR from 'components/Charts/SpecificFeedingRate/Chart';
import { ProjectContextType } from 'contexts/ProjectContext';
import { Site } from 'graphql/generated';
import { useState } from 'react';
import { ListItem, UnorderedList } from '@chakra-ui/react';

type FeedProps = {
  site?: Site;
  project: ProjectContextType;
};

const Feed = (props: FeedProps) => {
  const [feedUseByTypeSettings, setFeedUseByTypeSettings] = useState<FeedUseByTypeSettings>({
    selectedSublocations: ['All'],
    project: props.project,
    site: props?.site,
    dimension: 'name'
  });

  const [feedUseTotalToggle, setFeedUseTotalToggle] = useState(false);
  const [appetiteTotalToggle, setAppetiteToggle] = useState(false);

  return (
    <>
      <ChartTile
        hash="feed-use"
        heading="Feed Use"
        site={props.site}
        tooltip={
          <UnorderedList>
            <ListItem>
              Feed Use is summed while Appetite is averaged when using the Site toggle.
            </ListItem>
          </UnorderedList>
        }
        control={
          <ChartSwitchPanel
            switches={[
              {
                left: { value: 'sublocations', label: 'Sublocations' },
                right: { value: 'site', label: 'Site' },
                toggle: ({ checked }) => setFeedUseTotalToggle(checked)
              },
              {
                left: { value: 'feedUseKg', label: 'Feed Use (kg)' },
                right: { value: 'appetite', label: 'Appetite (%)' },
                toggle: ({ checked }) => setAppetiteToggle(checked)
              }
            ]}
          />
        }>
        <FeedUse
          settings={{
            project: props.project,
            site: props?.site,
            useTotal: feedUseTotalToggle,
            useAppetite: appetiteTotalToggle
          }}
        />
      </ChartTile>

      <ChartTile hash="feed-use-by-type" heading="Feed Use By Type" site={props.site}>
        <FeedUseByType
          settings={feedUseByTypeSettings}
          control={({ sublocations }) => (
            <FeedUseByTypeController
              chartInputs={{ sublocations }}
              setChartSettings={setFeedUseByTypeSettings}
              chartSettings={feedUseByTypeSettings}
            />
          )}
        />
      </ChartTile>

      <ChartTile hash="sfr" heading="Specific Feeding Rate" site={props.site}>
        <SFR settings={{ project: props.project, site: props?.site }} />
      </ChartTile>
    </>
  );
};

export default Feed;

import { Box } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { sum } from 'lodash';
import { useContext, useMemo } from 'react';
import { GiKitchenScale } from 'react-icons/gi';

const AverageWeightStat: HeadlineStat<'averageWeightKg'> = ({
  project,
  site,
  ...props
}: HeadlineStatProps) => {
  const { recencyMoniker, weeklyBiologyResultSet, weeklyBiologyIsLoading } =
    useContext(HeadlineDataContext);

  const latestBio = useMemo(() => {
    const set = weeklyBiologyResultSet?.rawData();
    if (!set || set.length === 0) return null;

    const latest = set[0]['Biology.measuredAt'];
    return set.filter((d) => d['Biology.measuredAt'] === latest);
  }, [weeklyBiologyResultSet]);

  const avgFishWeight = useMemo(() => {
    if (latestBio) {
      const nonZero = latestBio.map((d) => d['Biology.avgWeightD']).filter((w) => w > 0);
      return sum(nonZero) / nonZero.length;
    }
  }, [latestBio]);

  return (
    <Stat
      isLoading={weeklyBiologyIsLoading}
      key="average-weight-kg"
      cyId="headline-stat"
      label="Average Weight (kg)"
      value={safeFormat(avgFishWeight)}
      timeLabel={recencyMoniker(new Date(latestBio?.[0]?.['Biology.measuredAt']))}
      icon={
        <Box padding="5px" color="blue.500">
          <GiKitchenScale size={20} />
        </Box>
      }
      href={`/project/${project.id}/site/${site.id}/fish-health#average-weight`}
      {...props}
    />
  );
};

AverageWeightStat.statName = 'averageWeightKg';
AverageWeightStat.requiredDatasets = ['biology'];

export default AverageWeightStat;

import { Box, Image } from '@chakra-ui/react';
import { convertToIndex } from 'components/Charts/SIWI/SIWIShared';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { useContext, useMemo } from 'react';

const LatestSiwiStat: HeadlineStat<'latestSiwi'> = ({
  project,
  site,
  ...props
}: HeadlineStatProps) => {
  const { siwiResultSet, siwiIsLoading } = useContext(HeadlineDataContext);

  const siwiTotal = useMemo(() => {
    return project.siwi ? convertToIndex(siwiResultSet?.rawData()?.[0]?.['LatestSiwi.sT']) : 0;
  }, [siwiResultSet, project.siwi]);

  return (
    <Stat
      isLoading={siwiIsLoading}
      key="latest-siwi"
      cyId="headline-stat-siwi"
      label="Latest SIWI"
      timeLabel="Last 24 hours"
      value={safeFormat(siwiTotal, 0)}
      icon={
        <Box color="blue.500" m="5px">
          <Image src="/siwi_icon.jpg" h="20px" />
        </Box>
      }
      href={`/project/${project.id}/site/${site.id}/siwi#overview`}
      {...props}
    />
  );
};

LatestSiwiStat.statName = 'latestSiwi';
LatestSiwiStat.requiredDatasets = ['siwi'];

export default LatestSiwiStat;

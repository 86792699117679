import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { ChartInputs, ChartSettings } from './Chart';

const Controller = ({
  setChartSettings,
  chartSettings,
  chartInputs: { sublocations }
}: ChartController<ChartSettings, ChartInputs>) => {
  const selectOptions = [
    { label: 'All', value: 'All' },
    ...sublocations.map((s) => ({
      label: chartSettings?.site ? s : chartSettings.project.siteNameMappings[s],
      value: s
    }))
  ];

  const dimensionOptions = [
    { label: 'Feed Name', value: 'name' },
    { label: 'Product Name', value: 'product' },
    { label: 'Supplier', value: 'manufacturer' },
    { label: 'Feed Size', value: 'feedSizeMm' }
  ];

  return (
    <ChartSwitchPanel
      selects={[
        {
          value: selectOptions.filter((s) => chartSettings.selectedSublocations.includes(s.value)),
          minWidth: '600px',
          width: '100%',
          options: selectOptions,
          label: 'Sublocations',
          isMulti: true,
          isClearable: true,
          onChange: (options: { label: string; value: any }[]) => {
            const values = options?.map((v) => v.value);
            const selectedSublocations =
              values?.length === 0
                ? ['All']
                : values.length > 1 && values.includes('All')
                  ? values.filter((v) => v !== 'All')
                  : values;
            setChartSettings({
              ...chartSettings,
              selectedSublocations
            });
          }
        },
        {
          value: dimensionOptions.find((dopt) => dopt.value === chartSettings.dimension),
          minWidth: '150px',
          options: dimensionOptions,
          label: 'Dimension',
          onChange: (option: { label: string; value: ChartSettings['dimension'] }) => {
            setChartSettings({
              ...chartSettings,
              dimension: option.value
            });
          }
        }
      ]}
    />
  );
};

export default Controller;

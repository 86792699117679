import { ResultSet } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { formatDistance } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Project, Site } from 'graphql/generated';
import { capitalize } from 'lodash';
import { createContext, FC } from 'react';
import { MOTILE_LICE_STAGE_IDS_PROJECT_ID } from 'shared/Utils';

type HeadlineDataContextProviderType = {
  recencyMoniker: (date: Date) => string;
  manualHydrographyResultSet: ResultSet<ManualHydrographyStatData>;
  manualHydrographyIsLoading: boolean;
  mortalityCauseResultSet: ResultSet<MortalityStatData>;
  mortalityCauseIsLoading: boolean;
  weeklyBiologyResultSet: ResultSet<BiologyStatData>;
  weeklyBiologyIsLoading: boolean;
  weeklyFeedResultSet: ResultSet<FeedStatData>;
  weeklyFeedIsLoading: boolean;
  weeklyLiceResultSet: ResultSet<LiceStatData>;
  weeklyLiceIsLoading: boolean;
  tideWaterLevelsResultSet: ResultSet<TideWaterLevelStatData>;
  tideWaterLevelsIsLoading: boolean;
  tideHiLoResultSet: ResultSet<TideHiLoStatData>;
  tideHiLoIsLoading: boolean;
  siwiResultSet: ResultSet<SiwiStatData>;
  siwiIsLoading: boolean;
  planktonResultSet: ResultSet<PlanktonStatData>;
  planktonIsLoading: boolean;
};

export type HeadlineDatasetName =
  | 'manualHydrography'
  | 'mortality'
  | 'biology'
  | 'feed'
  | 'lice'
  | 'tideWaterLevels'
  | 'tideHiLo'
  | 'siwi'
  | 'plankton';

type TideWaterLevelStatData = {
  'TessTides.waterlevelAvg': number;
  'TessTides.measuredAt': string;
};

type TideHiLoStatData = {
  'TessTides.waterlevelAvg': number;
  'TessTides.measuredAt': string;
};

type LiceStatData = {
  'TessLice.avgLicePerFish': number;
  'TessLice.measuredAt': string;
};

type PlanktonStatData = {
  'TessLice.avgLicePerFish': number;
  'TessLice.measuredAt': string;
};

type ManualHydrographyStatData = {
  'ManualHydrography.salinityMax': number;
  'ManualHydrography.salinityMin': number;
  'ManualHydrography.oxygenSaturationMax': number;
  'ManualHydrography.oxygenSaturationMin': number;
  'ManualHydrography.waterTempMax': number;
  'ManualHydrography.waterTempMin': number;
  'ManualHydrography.measuredAt': string;
};

type MortalityStatData = {
  'TessMortality.mortalityCountSum': number;
  'TessMortalityLookup.mortalityCauseGroupName': string;
  'TessMortality.measuredAt': string;
};

type BiologyStatData = {
  'Biology.measuredAt': string;
  'Biology.specificFeedRateD': number;
  'Biology.feedUseWeightD': number;
  'Biology.endCount': number;
  'Biology.avgWeightD': number;
  'Biology.mortalityCount': number;
};

type FeedStatData = {
  'TessFeedUse.measuredAt': string;
  'TessFeedUse.usageWeightKgSum': number;
};

type SiwiStatData = {
  'LatestSiwi.sT': number;
  'LatestSiwi.measuredAt': string;
};

const HeadlineDataContext = createContext<HeadlineDataContextProviderType | null>(null);

type HeadlineDataContextProviderProps = {
  site: Site;
  project: Project;
  requestedDatasets: HeadlineDatasetName[];
};

const HeadlineDataContextProvider: FC<HeadlineDataContextProviderProps> = ({
  site,
  project,
  requestedDatasets,
  children
}) => {
  const recencyMoniker = (dateTime: Date) => {
    if (!dateTime || dateTime.toString() === 'Invalid Date') return 'Most Recent';
    const nowInTz = utcToZonedTime(
      zonedTimeToUtc(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone),
      project.timezone
    );
    const words = formatDistance(dateTime, nowInTz, {
      addSuffix: true
    });

    if (!Number.isInteger(words?.[0])) {
      return capitalize(words);
    } else {
      return words;
    }
  };

  const { resultSet: planktonResultSet, isLoading: planktonIsLoading } =
    useCubeQuery<PlanktonStatData>(
      {
        measures: ['LatestTessPlankton.avgCellCount', 'LatestTessPlankton.maxCellCount'],
        timeDimensions: [
          {
            dimension: 'LatestTessPlankton.measuredAt',
            dateRange: 'from 4320 minutes ago to now',
            granularity: 'minute'
          }
        ],
        dimensions: ['TessPlanktonLookup.species'],
        filters: [
          {
            member: 'Site.id',
            operator: 'equals',
            values: [site.smbId.toString()]
          },
          {
            member: 'TessPlanktonLookup.method',
            operator: 'equals',
            values: ['discrete']
          },
          {
            member: 'TessPlanktonLookup.sublocation',
            operator: 'set'
          }
        ],
        timezone: project.timezone
      },
      { subscribe: true, skip: !requestedDatasets.includes('plankton') }
    );

  const { resultSet: siwiResultSet, isLoading: siwiIsLoading } = useCubeQuery<SiwiStatData>(
    {
      measures: ['LatestSiwi.sT'],
      filters: [{ member: 'Site.id', operator: 'equals', values: [site.smbId.toString()] }],
      timezone: project.timezone,
      timeDimensions: [
        {
          dimension: 'LatestSiwi.measuredAt',
          granularity: 'day'
        }
      ]
    },
    { skip: !requestedDatasets.includes('siwi') }
  );

  const { resultSet: tideHiLoResultSet, isLoading: tideHiLoIsLoading } =
    useCubeQuery<TideHiLoStatData>(
      {
        measures: ['TessTides.waterlevelAvg'],
        timeDimensions: [
          {
            dimension: 'TessTides.measuredAt',
            granularity: 'minute',
            dateRange: 'next 720 minutes'
          }
        ],
        filters: [
          {
            member: 'TessTides.fromLat',
            operator: 'equals',
            values: [site?.lat ?? '']
          },
          {
            member: 'TessTides.fromLon',
            operator: 'equals',
            values: [site?.lon ?? '']
          },
          {
            member: 'TessTides.filterRadius',
            operator: 'equals',
            values: ['10000']
          },
          {
            member: 'TessTides.includeStationList',
            operator: 'equals',
            values: [site?.tideStations?.include.join(',') ?? 'NULL']
          },
          {
            member: 'TessTides.excludeStationList',
            operator: 'equals',
            values: [site?.tideStations?.exclude.join(',') ?? 'NULL']
          },
          {
            member: 'TessTides.hilo',
            operator: 'equals',
            values: ['true']
          }
        ],
        order: {
          'TessTides.measuredAt': 'asc'
        },
        timezone: project.timezone
      },
      { subscribe: true, skip: requestedDatasets.includes('tideHiLo') }
    );

  const { resultSet: tideWaterLevelsResultSet, isLoading: tideWaterLevelsIsLoading } =
    useCubeQuery<TideWaterLevelStatData>(
      {
        measures: ['TessTides.waterlevelAvg'],
        timeDimensions: [
          {
            dimension: 'TessTides.measuredAt',
            granularity: 'minute',
            dateRange: 'last 120 minutes from now'
          }
        ],
        filters: [
          {
            member: 'TessTides.fromLat',
            operator: 'equals',
            values: [site?.lat ?? '41']
          },
          {
            member: 'TessTides.fromLon',
            operator: 'equals',
            values: [site?.lon ?? '-131']
          },
          {
            member: 'TessTides.filterRadius',
            operator: 'equals',
            values: ['10000']
          },
          {
            member: 'TessTides.includeStationList',
            operator: 'equals',
            values: [site?.tideStations?.include.join(',') ?? 'NULL']
          },
          {
            member: 'TessTides.excludeStationList',
            operator: 'equals',
            values: [site?.tideStations?.exclude.join(',') ?? 'NULL']
          }
        ],
        order: {
          'TessTides.measuredAt': 'desc'
        },
        timezone: project.timezone
      },
      { subscribe: true, skip: !requestedDatasets.includes('tideWaterLevels') }
    );

  const { resultSet: weeklyLiceResultSet, isLoading: weeklyLiceIsLoading } =
    useCubeQuery<LiceStatData>(
      {
        measures: ['TessLice.avgLicePerFish'],
        timeDimensions: [
          {
            dimension: 'TessLice.measuredAt',

            dateRange: 'from 7 days ago to now'
          }
        ],
        filters: [
          {
            member: 'Site.id',
            operator: 'equals',
            values: [site.smbId.toString()]
          },
          {
            member: 'TessLiceLookup.stageId',
            operator: 'equals',
            values: MOTILE_LICE_STAGE_IDS_PROJECT_ID[project.id]
          }
        ],
        timezone: project.timezone
      },
      {
        subscribe: true,
        skip:
          (MOTILE_LICE_STAGE_IDS_PROJECT_ID?.[project.id]?.length ?? 0) === 0 ||
          !requestedDatasets.includes('lice')
      }
    );

  const { resultSet: manualHydrographyResultSet, isLoading: manualHydrographyIsLoading } =
    useCubeQuery<ManualHydrographyStatData>(
      {
        measures: [
          'ManualHydrography.salinityMax',
          'ManualHydrography.salinityMin',
          'ManualHydrography.oxygenSaturationMax',
          'ManualHydrography.oxygenSaturationMin',
          'ManualHydrography.waterTempMax',
          'ManualHydrography.waterTempMin'
        ],
        timeDimensions: [
          {
            dimension: 'ManualHydrography.measuredAt',
            granularity: 'hour',
            dateRange: 'from 168 hours ago to now'
          }
        ],
        filters: [
          {
            member: 'Site.id',
            operator: 'equals',
            values: [site.smbId.toString()]
          },
          {
            member: 'ManualHydrography.salinity',
            operator: 'set'
          },
          {
            member: 'ManualHydrography.waterTemp',
            operator: 'set'
          },
          {
            member: 'ManualHydrography.oxygenSaturation',
            operator: 'set'
          }
        ],
        order: { 'ManualHydrography.measuredAt': 'desc' },
        limit: 1,
        timezone: project.timezone
      },
      { subscribe: true, skip: !requestedDatasets.includes('manualHydrography') }
    );

  const { resultSet: weeklyBiologyResultSet, isLoading: weeklyBiologyIsLoading } =
    useCubeQuery<BiologyStatData>(
      {
        dimensions: [
          'Biology.measuredAt',
          'Biology.specificFeedRateD',
          'Biology.feedUseWeightD',
          'Biology.endCount',
          'Biology.avgWeightD',
          'Biology.mortalityCount'
        ],
        timeDimensions: [
          {
            dimension: 'Biology.measuredAt',
            dateRange: 'from 7 days ago to now'
          }
        ],
        filters: [
          {
            member: 'Site.id',
            operator: 'equals',
            values: [site.smbId.toString()]
          }
        ],

        order: { 'Biology.measuredAt': 'desc' },
        timezone: project.timezone
      },
      { subscribe: true, skip: !requestedDatasets.includes('biology') }
    );

  const { resultSet: mortalityCauseResultSet, isLoading: mortalityCauseIsLoading } =
    useCubeQuery<MortalityStatData>(
      {
        measures: ['TessMortality.mortalityCountSum'],
        dimensions: ['TessMortalityLookup.mortalityCauseGroupName'],
        timeDimensions: [
          {
            dimension: 'TessMortality.measuredAt',
            dateRange: 'from 7 days ago to now'
          }
        ],
        filters: [
          {
            member: 'Site.id',
            operator: 'equals',
            values: [site.smbId.toString()]
          },
          {
            member: 'TessMortality.mortalityCountSum',
            operator: 'gt',
            values: ['0']
          }
        ],

        order: { 'TessMortality.mortalityCountSum': 'desc' },
        limit: 3,
        timezone: project.timezone
      },
      { subscribe: true, skip: !requestedDatasets.includes('mortality') }
    );

  const { resultSet: weeklyFeedResultSet, isLoading: weeklyFeedIsLoading } =
    useCubeQuery<FeedStatData>(
      {
        measures: ['TessFeedUse.usageWeightKgSum'],
        timeDimensions: [
          {
            dimension: 'TessFeedUse.measuredAt',
            dateRange: 'from 7 days ago to now'
          }
        ],
        filters: [
          {
            member: 'Site.id',
            operator: 'equals',
            values: [site.smbId.toString()]
          }
        ],

        order: { 'TessFeedUse.measuredAt': 'desc' },
        timezone: project.timezone
      },
      { subscribe: true, skip: !requestedDatasets.includes('feed') }
    );

  return (
    <HeadlineDataContext.Provider
      value={{
        recencyMoniker,
        manualHydrographyResultSet,
        manualHydrographyIsLoading,
        mortalityCauseResultSet,
        mortalityCauseIsLoading,
        weeklyBiologyResultSet,
        weeklyBiologyIsLoading,
        weeklyFeedResultSet,
        weeklyFeedIsLoading,
        weeklyLiceResultSet,
        weeklyLiceIsLoading,
        tideWaterLevelsResultSet,
        tideWaterLevelsIsLoading,
        tideHiLoResultSet,
        tideHiLoIsLoading,
        siwiResultSet,
        siwiIsLoading,
        planktonResultSet,
        planktonIsLoading
      }}>
      {children}
    </HeadlineDataContext.Provider>
  );
};

export { HeadlineDataContextProvider, HeadlineDataContext };

import { EditIcon } from '@chakra-ui/icons';
import { Box, Container, Heading, HStack, IconButton, Link } from '@chakra-ui/react';
import ProjectCard from 'components/Picker/ProjectCard';
import { UserContext } from 'contexts/UserContext';
import { cloneDeep, sortBy } from 'lodash';
import { useContext, useMemo } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';

const ProjectPicker = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const sortedOrgProjects = useMemo(() => {
    const orgs = cloneDeep(sortBy(user.organizations, 'name'));
    orgs.forEach((o) => {
      o.projects = sortBy(o.projects, 'name');
    });

    return orgs;
  }, [user.organizations]);

  return (
    <Container maxWidth={['90vw', null, '80vw']} marginTop={10}>
      <Heading as="h1" size={'2xl'} fontWeight={'normal'} textAlign={'center'} marginBottom={10}>
        SeaState Projects
      </Heading>
      <HStack flexWrap="wrap" rowGap="20px" justifyContent="center">
        {sortedOrgProjects.map((o) => {
          return o.projects.map((p) => {
            return (
              <Box key={p.id}>
                {user.superuser && (
                  <IconButton
                    position="relative"
                    top={10}
                    color="blue.500"
                    icon={<EditIcon />}
                    aria-label={'Edit'}
                    onClick={() => navigate(`/project/${p.id}/edit`)}
                  />
                )}
                <Link as={ReactLink} to={'/project/' + p.id}>
                  <ProjectCard
                    latitude={p.latitude as number}
                    longitude={p.longitude as number}
                    organizationName={o.name}
                    projectName={p.name}
                  />
                </Link>
              </Box>
            );
          });
        })}
      </HStack>
    </Container>
  );
};

export default ProjectPicker;

import AverageWeightStat from 'components/Site/Headline/Stats/AverageWeightStat';
import FeedUseStat from 'components/Site/Headline/Stats/FeedUseStat';
import LatestSiwiStat from 'components/Site/Headline/Stats/LatestSiwiStat';
import MortalityCountDailyStat from 'components/Site/Headline/Stats/MortalityCountDailyStat';
import MortalityCountWeeklyStat from 'components/Site/Headline/Stats/MortalityCountWeeklyStat';
import MotileLicePerFishStat from 'components/Site/Headline/Stats/MotileLicePerFishStat';
import TideHiLoStat from 'components/Site/Headline/Stats/NextTideHiLoStat';
import OxygenPercentStat from 'components/Site/Headline/Stats/OxygenPercentStat';
import PlanktonCellsStat from 'components/Site/Headline/Stats/PlanktonCellsStat';
import SalinityPsuStat from 'components/Site/Headline/Stats/SalinityPsuStat';
import SfrAvgStat from 'components/Site/Headline/Stats/SfrAvgStat';
import TideNowStat from 'components/Site/Headline/Stats/TideNowStat';
import TopMortalityStat from 'components/Site/Headline/Stats/TopMortalityStat';
import TotalFishCountStat from 'components/Site/Headline/Stats/TotalFishCountStat';
import WaterTemperatureStat from 'components/Site/Headline/Stats/WaterTemperatureStat';
import { TileProps } from 'components/Tile';
import { HeadlineDatasetName } from 'contexts/HeadlineDataContext';
import { ProjectContextType } from 'contexts/ProjectContext';
import { Site } from 'graphql/generated';

export type HeadlineStatName =
  | 'waterTemperature'
  | 'oxygenPercent'
  | 'salinity'
  | 'totalFishCount'
  | 'averageWeightKg'
  | 'mortalityCountDaily'
  | 'mortalityCountWeekly'
  | 'topMortalityCauses'
  | 'feedUseKg'
  | 'sfrAvg'
  | 'motileLicePerFish'
  | 'tideWaterLevel'
  | 'tideHiLo'
  | 'latestSiwi'
  | 'latestPlanktonCells';

export type HeadlineStatProps = {
  project: ProjectContextType;
  site: Site;
};

export type HeadlineStat<T extends HeadlineStatName> = React.FC<HeadlineStatProps & TileProps> & {
  statName: T;
  requiredDatasets: HeadlineDatasetName[];
};

export const safeFormat = (value?: number, digits = 2, placeholder = '-') =>
  value ? Number(value.toFixed(digits)).toLocaleString() : placeholder;

const useHeadlineStats = ({ statNames }: { statNames: HeadlineStatName[] | 'all' }) => {
  const allHeadlineStats: HeadlineStat<HeadlineStatName>[] = [
    WaterTemperatureStat,
    OxygenPercentStat,
    SalinityPsuStat,
    TotalFishCountStat,
    AverageWeightStat,
    MortalityCountDailyStat,
    MortalityCountWeeklyStat,
    TopMortalityStat,
    FeedUseStat,
    SfrAvgStat,
    MotileLicePerFishStat,
    TideNowStat,
    TideHiLoStat,
    LatestSiwiStat,
    PlanktonCellsStat
  ];

  if (statNames === 'all') return allHeadlineStats;

  const selectedStats = statNames
    .map((statName) => allHeadlineStats.find((headlineStat) => headlineStat.statName === statName))
    .filter((u) => u);

  return selectedStats;
};

export default useHeadlineStats;

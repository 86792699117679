import { Box } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { sum } from 'lodash';
import { useContext, useMemo } from 'react';
import { GiCirclingFish } from 'react-icons/gi';

const TotalFishCountStat: HeadlineStat<'totalFishCount'> = ({
  project,
  site,
  ...props
}: HeadlineStatProps) => {
  const { recencyMoniker, weeklyBiologyResultSet, weeklyBiologyIsLoading } =
    useContext(HeadlineDataContext);

  const latestBio = useMemo(() => {
    const set = weeklyBiologyResultSet?.rawData();
    if (!set || set.length === 0) return null;

    const latest = set[0]['Biology.measuredAt'];
    return set.filter((d) => d['Biology.measuredAt'] === latest);
  }, [weeklyBiologyResultSet]);

  const totalFishCount = useMemo(() => {
    if (latestBio) return sum(latestBio.map((d) => d['Biology.endCount']));
  }, [latestBio]);

  return (
    <Stat
      isLoading={weeklyBiologyIsLoading}
      key="total-fish-count"
      cyId="headline-stat"
      label="Total Fish (count)"
      value={safeFormat(totalFishCount)}
      timeLabel={recencyMoniker(new Date(latestBio?.[0]?.['Biology.measuredAt']))}
      icon={
        <Box padding="5px" color="blue.500">
          <GiCirclingFish size={20} />
        </Box>
      }
      href={`/project/${project.id}/site/${site.id}/fish-health#total-fish`}
      {...props}
    />
  );
};

TotalFishCountStat.statName = 'totalFishCount';
TotalFishCountStat.requiredDatasets = ['biology'];

export default TotalFishCountStat;

import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { startCase } from 'lodash';
import { Fragment, useContext, useMemo } from 'react';
import { GiFishbone } from 'react-icons/gi';

const TopMortalityStat: HeadlineStat<'topMortalityCauses'> = ({
  project,
  site,
  ...props
}: HeadlineStatProps) => {
  const { mortalityCauseResultSet, mortalityCauseIsLoading } = useContext(HeadlineDataContext);

  const mortCauses = useMemo(() => {
    if (mortalityCauseResultSet?.rawData()) {
      return mortalityCauseResultSet.rawData();
    }
  }, [mortalityCauseResultSet]);

  return (
    <Stat
      isLoading={mortalityCauseIsLoading}
      key="top-mortality"
      cyId="headline-stat"
      label="Top Mortality Causes"
      value={
        mortCauses?.length ? (
          <SimpleGrid columns={2}>
            {mortCauses?.map((mortInfo) => {
              return (
                <Fragment key={`group-${mortInfo['TessMortalityLookup.mortalityCauseGroupName']}`}>
                  <Text align="start" w="100%" fontSize="xs">
                    {startCase(mortInfo['TessMortalityLookup.mortalityCauseGroupName'])}
                  </Text>
                  <Text align="end" w="100%" fontSize="xs">
                    {safeFormat(mortInfo['TessMortality.mortalityCountSum'], 0)}
                  </Text>
                </Fragment>
              );
            })}
          </SimpleGrid>
        ) : (
          <Text>-</Text>
        )
      }
      timeLabel="Last 7 days"
      icon={
        <Box padding="5px" color="blue.500">
          <GiFishbone size={20} />
        </Box>
      }
      href={`/project/${project.id}/site/${site.id}/fish-health#mortality-by-cause`}
      {...props}
    />
  );
};

TopMortalityStat.statName = 'topMortalityCauses';
TopMortalityStat.requiredDatasets = ['mortality'];

export default TopMortalityStat;

import { Box, BoxProps, StyleProps } from '@chakra-ui/react';
import React, { HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
import './Tile.css';

export type TileProps = {
  clickable?: boolean;
  href?: string;
} & BoxProps;

const retry = async (fn, ms = 200, retries = 25, err = null) => {
  if (!retries) {
    return Promise.reject(err);
  }

  await new Promise((resolve) => setTimeout(resolve, ms));

  if (!fn) return;
  return fn().catch((err) => {
    return retry(fn, ms, retries - 1, err);
  });
};

const Tile: React.FC<StyleProps & Omit<HTMLAttributes<HTMLDivElement>, 'color'> & TileProps> = ({
  children,
  href,
  clickable,
  ...base
}) => {
  const navigate = useNavigate();

  const nav = () => {
    if (href) {
      const anchorSplit = href.split('#');

      if (anchorSplit.length > 0) {
        navigate(anchorSplit[0]);
        if (anchorSplit.length > 1) {
          retry(() => {
            const el = document.getElementById(anchorSplit[1]);
            if (el) {
              el.scrollIntoView({ behavior: 'smooth' });
            } else {
              throw 'element not found yet';
            }
          });
        }
      }
    }
  };

  return (
    <Box
      onClick={nav}
      boxShadow="base"
      borderRadius="base"
      cursor={clickable || href ? 'pointer' : 'default'}
      {...base}
      className={clickable || href ? 'hover-highlight' : ''}>
      {children}
    </Box>
  );
};

export default Tile;

import { Box, HStack, Text } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { format } from 'date-fns';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { useContext, useMemo } from 'react';
import { BiWater } from 'react-icons/bi';
import { GiHighTide, GiLowTide } from 'react-icons/gi';

const TideHiLoStat: HeadlineStat<'tideHiLo'> = ({ project, site, ...props }: HeadlineStatProps) => {
  const { tideHiLoResultSet, tideHiLoIsLoading } = useContext(HeadlineDataContext);

  const [currentHilo, nextHilo] = useMemo(() => {
    const current = tideHiLoResultSet?.rawData()?.[0];
    const next = tideHiLoResultSet?.rawData()?.[1];
    if (!current || !next) return [null, null];
    return [
      { value: Number(current['TessTides.waterlevelAvg']), time: current['TessTides.measuredAt'] },
      { value: Number(next['TessTides.waterlevelAvg']), time: next['TessTides.measuredAt'] }
    ];
  }, [tideHiLoResultSet]);

  return (
    <Stat
      isLoading={tideHiLoIsLoading}
      key="next-tide"
      cyId="headline-stat"
      label="Next Tide High/Low (m)"
      timeLabel={currentHilo?.time ? `At ${format(Date.parse(currentHilo?.time), 'HH:mm')}` : '-'}
      value={
        <HStack justifyContent="center" w="100%">
          <Text>{safeFormat(currentHilo?.value)}</Text>
          {currentHilo?.value && nextHilo?.value && (
            <Box color="blue.500">
              {currentHilo.value < nextHilo.value ? (
                <GiLowTide style={{ marginBottom: '6px' }} />
              ) : (
                <GiHighTide style={{ marginBottom: '6px' }} />
              )}
            </Box>
          )}
        </HStack>
      }
      icon={
        <Box padding="5px" color="blue.500">
          <BiWater size={20} />
        </Box>
      }
      href={`/project/${project.id}/site/${site.id}/environment#tides`}
      {...props}
    />
  );
};

TideHiLoStat.statName = 'tideHiLo';
TideHiLoStat.requiredDatasets = ['tideWaterLevels'];

export default TideHiLoStat;

import { Box, BoxProps, Text, Wrap } from '@chakra-ui/react';
import { Notice } from 'components/Notice';
import { HeadlineDataContextProvider } from 'contexts/HeadlineDataContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { Site } from 'graphql/generated';
import useHeadlineStats, { HeadlineStatName } from 'hooks/useHeadlineStats';
import { sortBy, uniq } from 'lodash';
import { useContext } from 'react';

export const safeFormat = (value?: number, digits = 2, placeholder = '-') =>
  value ? Number(value.toFixed(digits)).toLocaleString() : placeholder;

const HeadlineStats = ({ site, ...props }: { site: Site } & BoxProps) => {
  const project = useContext(ProjectContext);
  const stats = useHeadlineStats({
    statNames: sortBy(site.headlineStats, 'order').map((hs) => hs.key as HeadlineStatName)
  });

  return (
    <Box w="100%" m="1rem" textAlign="center" {...props}>
      <Text fontSize="3xl">{site.name}</Text>
      {site.headlineStats.length ?
      <HeadlineDataContextProvider
        site={site}
        project={project}
        requestedDatasets={uniq(stats.flatMap((s) => s.requiredDatasets))}>
        <Wrap p="5px" w="100%">
          {stats.map((StatComponent, index) => (
            <StatComponent key={index} project={project} site={site} />
          ))}
        </Wrap>
      </HeadlineDataContextProvider> : <Notice h="100%"><Text>No headline tiles assigned.</Text></Notice>}
    </Box>
  );
};

export default HeadlineStats;

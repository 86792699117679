import { Box, Text } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { capitalize, uniq } from 'lodash';
import { useContext, useMemo } from 'react';
import { GiLongAntennaeBug } from 'react-icons/gi';
import { thresholdForSpeciesValue } from 'shared/plankton';

type PlanktonAbundance = {
  safe: { species: string; concentration: number; time: Date; numberSpp: number };
  caution: { species: string; concentration: number; time: Date; numberSpp: number };
  lethal: { species: string; concentration: number; time: Date; numberSpp: number };
};

const PlanktonCellsStat: HeadlineStat<'latestPlanktonCells'> = ({
  project,
  site,
  ...props
}: HeadlineStatProps) => {
  const { recencyMoniker, planktonResultSet, planktonIsLoading } = useContext(HeadlineDataContext);

  const planktonSpp: PlanktonAbundance = useMemo(() => {
    const plankton = planktonResultSet?.rawData();
    if (plankton) {
      const mostAbundant = {} as PlanktonAbundance;
      const allSpp = [];
      Object.values(plankton).map((d) => {
        allSpp.push(d['TessPlanktonLookup.species']);
        const thisConcentration = d['LatestTessPlankton.maxCellCount'];
        const threshold = thresholdForSpeciesValue(
          project,
          d['TessPlanktonLookup.species'],
          d['LatestTessPlankton.maxCellCount']
        );
        mostAbundant[threshold] = mostAbundant[threshold] ?? {
          species: '',
          concentration: 0,
          time: '',
          numberSpp: 0
        };
        if (mostAbundant[threshold]['concentration'] < thisConcentration) {
          mostAbundant[threshold]['species'] = d['TessPlanktonLookup.species'];
          mostAbundant[threshold]['concentration'] = thisConcentration;
          mostAbundant[threshold]['time'] = d['LatestTessPlankton.measuredAt'];
        }
      });
      mostAbundant['numberSpp'] = uniq(allSpp).length;
      return mostAbundant;
    } else
      return {
        safe: { species: null, concentration: null, time: null, numberSpp: null },
        caution: { species: null, concentration: null, time: null, numberSpp: null },
        lethal: { species: null, concentration: null, time: null, numberSpp: null }
      };
  }, [planktonResultSet, project]);

  const scariestPlankton = planktonSpp.lethal
    ? { ...planktonSpp.lethal, threshold: 'danger' }
    : planktonSpp.caution
      ? { ...planktonSpp.caution, threshold: 'caution' }
      : { ...planktonSpp.safe, threshold: 'safe' };

  return (
    <Stat
      isLoading={planktonIsLoading}
      key="plankton-cells-ml"
      cyId="headline-stat"
      label="Plankton (cells/mL)"
      timeLabel={recencyMoniker(new Date(scariestPlankton?.time))}
      value={
        <>
          <Box fontSize="medium" fontWeight={'bold'} color={'black'}>{`${
            scariestPlankton.concentration ? `${capitalize(scariestPlankton.threshold)}: ` : ''
          }${safeFormat(Number(scariestPlankton.concentration))}`}</Box>
          <Box>
            <Text fontSize="small" color="gray.500">
              {scariestPlankton?.species &&
                project.findPlanktonPolicy(scariestPlankton.species).name}
            </Text>
          </Box>
        </>
      }
      icon={
        <Box padding="5px" color="blue.500">
          <GiLongAntennaeBug size={20} />
        </Box>
      }
      href={`/project/${project.id}/site/${site.id}/plankton/data#latest-sample`}
      {...props}
    />
  );
};

PlanktonCellsStat.statName = 'latestPlanktonCells';
PlanktonCellsStat.requiredDatasets = ['plankton'];

export default PlanktonCellsStat;

import { gql, useQuery } from '@apollo/client';
import { Skeleton, Spacer } from '@chakra-ui/react';
import { FeedType } from 'graphql/generated';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, sum, uniq } from 'lodash';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { useMemo } from 'react';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot, { plotDates } from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';

const GET_FEED_TYPES = gql`
  query GetFeedTypes {
    feedTypes {
      id
      manufacturer
      name
      feedSizeMm
    }
  }
`;

type FeedUseByTypeDatum = {
  'TessFeedUseLookup.sublocation'?: string;
  'TessFeedUseLookup.feedTypeId': number;
  'Site.id'?: string;
  'TessFeedUse.measuredAt': string;
  'TessFeedUse.usageWeightKgSum': number;
};

type FeedUseByTypeStructure = {
  [dimension: string]: {
    weightKgSum: number;
    measuredAt: string;
    sublocation: string;
  }[];
};

export type ChartSettings = BaseChartSettings & {
  selectedSublocations: string[];
  dimension: 'name' | 'manufacturer' | 'feedSizeMm';
};

export type ChartInputs = {
  sublocations: string[];
};

const Chart = ({
  granularity = 'day',
  dateRange = 'Last 30 days',
  chartRange,
  skip,
  settings,
  control,
  onDataLoaded
}: BaseChartProps<ChartSettings, ChartInputs>) => {
  const { data: feedTypeData } = useQuery(GET_FEED_TYPES);

  const locationDimension = settings.site?.smbId ? 'TessFeedUseLookup.sublocation' : 'Site.id';

  const transform = (
    data: FeedUseByTypeDatum[],
    dependencies: {
      sublocations: string[];
      chartRange: [Date, Date];
      feedTypes: FeedType[];
      dimension: string;
    }
  ): FeedUseByTypeStructure => {
    let sublocationFilteredData = data;
    if (!settings.selectedSublocations.includes('All')) {
      // filter to sublocation
      sublocationFilteredData = data.filter((d) =>
        settings.selectedSublocations.includes(d[locationDimension])
      );
    }

    const mergedWithFeedTypes = sublocationFilteredData
      .map((sublocationData) => ({
        ...sublocationData,
        feedType: dependencies.feedTypes.find(
          (ft) => ft.id === sublocationData['TessFeedUseLookup.feedTypeId']
        )
      }))
      .filter((d) => d?.feedType);

    // const groupByProperty = settings.dimension === 'name' ? 'TessFeedUseLookup.feedTypeId'
    let groupedByDimension = {};

    if (settings.dimension === 'manufacturer') {
      groupedByDimension = groupBy(mergedWithFeedTypes, (d) => d.feedType.manufacturer);
    } else if (settings.dimension === 'feedSizeMm') {
      groupedByDimension = groupBy(mergedWithFeedTypes, (d) => d.feedType?.feedSizeMm ?? 'Unknown');
    } else if (settings.dimension === 'name') {
      groupedByDimension = groupBy(mergedWithFeedTypes, (d) => d.feedType.name.replace(/^\d+/,''));
    } else {
      groupedByDimension = groupBy(mergedWithFeedTypes, (d) => d.feedType.name);
    }

    const output = Object.keys(groupedByDimension).reduce((acc, dimension: string) => {
      const byMeasuredAt = groupedByDimension[dimension].map((datum) => ({
        weightKgSum: datum['TessFeedUse.usageWeightKgSum'],
        measuredAt: datum[`TessFeedUse.measuredAt.${granularity}`],
        sublocation: datum[locationDimension]
      }));
      acc[dimension] = byMeasuredAt;
      return acc;
    }, {});

    return output;
  };

  const graph = (data: FeedUseByTypeStructure): PlotlyDataLayoutConfig => {
    const uniqDimensions = uniq(Object.keys(data));

    const pallet = createLocationPallet({ locations: uniqDimensions });

    //@ts-ignore
    const plotData: Data[] = uniqDimensions.map((dimension) => {
      const dimensionData = data[dimension];
      const timeGrouped = groupBy(dimensionData, 'measuredAt');

      const timeAggregatedData = Object.values(timeGrouped).map((timeGroupedValues) => {
        const weightSum = sum(timeGroupedValues.map((v) => Number(v.weightKgSum)));

        return {
          weightKgSum: weightSum,
          measuredAt: timeGroupedValues[0].measuredAt
        };
      });

      const uniqueSublocations: string[] = uniq(dimensionData.map((d) => d.sublocation)).sort(
        (a, b) => locationToIndex(a) - locationToIndex(b)
      );

      const traceName =
        settings.dimension === 'feedSizeMm' && dimension !== 'Unknown'
          ? `${dimension}mm`
          : dimension;

      return {
        type: 'bar',
        name: traceName,
        x: timeAggregatedData.map((d) => d.measuredAt),
        y: timeAggregatedData.map((d) => d.weightKgSum),
        marker: {
          color: pallet[dimension]
        },
        showlegend: true,
        hovertemplate: `<b>%{x}</b><br>Name: <b>%{text.traceName}</b><br><br>%{text.sublocations}<br><br>Total: <b>%{y:0.f}</b><extra></extra>`,
        hoverlabel: {
          align: 'left'
        },
        text: timeAggregatedData.map((d) => {
          const sublocationDetails = uniqueSublocations
            .map((sublocation) => {
              const sublocationData = dimensionData.filter(
                (dd) => dd.sublocation === sublocation && dd.measuredAt === d.measuredAt
              );
              if (sublocationData.length === 0) return null;
              const displayName = settings.site?.smbId
                ? sublocation
                : settings.project.siteNameMappings[sublocation];

              const sublocationSum = sum(sublocationData?.map((d) => Number(d.weightKgSum)));

              return `${displayName}: <b>${sublocationSum}</b>`;
            })
            .filter((s) => s)
            .join('<br>');

          return {
            value: d.weightKgSum,
            sublocations: sublocationDetails,
            traceName
          };
        })
      };
    });

    const typeLabel = 'Feed Use Weight (kg)';

    const measurementLabel = `${typeLabel} By ${settings.dimension === 'name' ? 'Name' : settings.dimension === 'manufacturer' ? 'Supplier' : 'Size (mm)'}`;

    const title = settings?.site
      ? `${typeLabel} - ${settings.site.name}`
      : `${typeLabel} - All Sites`;

    const [minDate, maxDate] = plotDates(plotData);

    const layout = {
      title: settings?.showTitle && {
        text: title,
        y: 1
      },
      barmode: 'stack',
      legend: {
        orientation: 'h',
        x: 0,
        y: 1.5
      },
      yaxis: {
        title: {
          text: measurementLabel,
          font: {
            size: 14
          }
        }
      },
      xaxis: {
        title: `${minDate} - ${maxDate} by ${granularity}`,
        // type: 'multicategory',
        range: chartRange
      },
      autosize: true
    };

    return {
      //@ts-ignore
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const { isLoading, error, plot, resultSet } = useCubeLTG({
    cubeQuery: {
      measures: ['TessFeedUse.usageWeightKgSum'],
      timeDimensions: [
        {
          dimension: 'TessFeedUse.measuredAt',
          granularity,
          dateRange
        }
      ],
      dimensions: ['TessFeedUseLookup.feedTypeId', locationDimension],
      filters: [
        {
          member: 'TessFeedUse.usageWeightKg',
          operator: 'gt',
          values: ['0']
        },
        ...(settings?.site
          ? [
              {
                member: 'Site.id',
                operator: 'equals' as const,
                values: [settings.site?.smbId.toString()]
              }
            ]
          : [])
      ],
      order: { 'TessFeedUse.measuredAt': 'asc' },
      timezone: settings.project.timezone
    },
    transform,
    graph,
    options: {
      skip,
      dependencies: {
        dimension: settings.dimension,
        sublocations: settings.selectedSublocations,
        chartRange,
        feedTypes: feedTypeData?.feedTypes ?? []
      },
      onDataLoaded
    }
  });

  const sublocations = useMemo(() => {
    return uniq(resultSet?.rawData().map((d) => d[locationDimension])).sort(
      (a, b) => locationToIndex(a) - locationToIndex(b)
    );
  }, [resultSet]);

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length ? (
    <>
      {control ? control({ sublocations: sublocations }) : <></>}
      <Spacer mt="20px" />
      <Plot className="w-100" useResizeHandler={true} {...plot} />
    </>
  ) : plot?.data?.length === 0 && settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <>
      {control ? control({ sublocations: sublocations }) : <></>}
      <Spacer mt="20px" />
      <NoData minH="450px" />
    </>
  );
};

export default Chart;

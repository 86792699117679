import { Box, HStack, Text } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { useContext, useMemo } from 'react';
import { BiWater } from 'react-icons/bi';
import { HiArrowDown, HiArrowUp } from 'react-icons/hi';

const TideNowStat: HeadlineStat<'tideWaterLevel'> = ({
  project,
  site,
  ...props
}: HeadlineStatProps) => {
  const { tideWaterLevelsResultSet, tideWaterLevelsIsLoading } = useContext(HeadlineDataContext);

  const [currentTide, lastTide] = useMemo(() => {
    if (!tideWaterLevelsResultSet?.rawData()?.[0] || !tideWaterLevelsResultSet?.rawData()?.[1])
      return [null, null];
    return [
      Number(tideWaterLevelsResultSet?.rawData()?.[0]['TessTides.waterlevelAvg']),
      Number(tideWaterLevelsResultSet?.rawData()?.[1]['TessTides.waterlevelAvg'])
    ];
  }, [tideWaterLevelsResultSet]);

  return (
    <Stat
      isLoading={tideWaterLevelsIsLoading}
      key="tide-height"
      cyId="headline-stat"
      label="Tide Height (m)"
      timeLabel="Now"
      value={
        <HStack justifyContent="center" w="100%">
          <Text>{safeFormat(currentTide)}</Text>
          {currentTide && lastTide && (
            <Box color="blue.500">
              {currentTide < lastTide ? (
                <HiArrowDown style={{ marginBottom: '2px' }} />
              ) : (
                <HiArrowUp style={{ marginBottom: '2px' }} />
              )}
            </Box>
          )}
        </HStack>
      }
      icon={
        <Box padding="5px" color="blue.500">
          <BiWater size={20} />
        </Box>
      }
      href={`/project/${project.id}/site/${site.id}/environment#tides`}
      {...props}
    />
  );
};

TideNowStat.statName = 'tideWaterLevel';
TideNowStat.requiredDatasets = ['tideWaterLevels'];

export default TideNowStat;

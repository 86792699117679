import { Box } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { sum } from 'lodash';
import { useContext, useMemo } from 'react';
import { TbGrain } from 'react-icons/tb';

const FeedUseStat: HeadlineStat<'feedUseKg'> = ({ project, site, ...props }: HeadlineStatProps) => {
  const { weeklyFeedResultSet, weeklyFeedIsLoading } = useContext(HeadlineDataContext);

  const weeklyFeed = useMemo(() => {
    if (weeklyFeedResultSet?.rawData()) {
      return sum(weeklyFeedResultSet?.rawData().map((d) => d['Biology.feedUseWeightD']));
    }
  }, [weeklyFeedResultSet]);

  return (
    <Stat
      isLoading={weeklyFeedIsLoading}
      key="feed-use-kg"
      cyId="headline-stat"
      label="Feed Use (kg)"
      value={safeFormat(weeklyFeed, 0)}
      timeLabel="Last 7 days"
      icon={
        <Box padding="5px" color="blue.500">
          <TbGrain size={20} />
        </Box>
      }
      href={`/project/${project.id}/site/${site.id}/feed#feed-use`}
      {...props}
    />
  );
};

FeedUseStat.statName = 'feedUseKg';
FeedUseStat.requiredDatasets = ['feed'];

export default FeedUseStat;

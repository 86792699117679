import { gql, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Center,
  Heading,
  IconButton,
  Input,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  VStack
} from '@chakra-ui/react';
import { permissions } from '@scoot/permissions';
import FormCheckbox from 'components/Forms/FormCheckbox';
import FormInput from 'components/Forms/FormInput';
import FormSelect from 'components/Forms/FormSelect';
import { tabArray } from 'components/Project/tabs';
import Tile from 'components/Tile';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { isEmpty, sortBy, startCase } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoMdSettings } from 'react-icons/io';
import { Navigate, Link } from 'react-router-dom';

const GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    organizations {
      id
      name
    }
  }
`;

const VALID_TIMEZONE_OPTIONS = [
  'Canada/Pacific',
  'Europe/Oslo',
  'Europe/London',
  'Australia/Brisbane'
].map((tz) => ({ label: tz, value: tz }));

interface ProjectForm {
  name: string;
  organization: { label: string; value: number };
  active: boolean;
  forecasting: boolean;
  freeTrial: boolean;
  dataEntry: string[];
  timezone: { label: string; value: string };
  siwi: boolean;
  regionId: number;
  overviewTabs: { label: string; value: string }[];
}

const ProjectIdEdit = () => {
  const project = useContext(ProjectContext);
  const user = useContext(UserContext);
  const toast = useToast();

  const [siteFilter, setSiteFilter] = useState('');
  const { data: organizationData, loading: organizationsLoading } = useQuery(GET_ORGANIZATIONS, {
    skip: !user.superuser
  });

  const organizationOptions = useMemo(() => {
    if (!organizationData) return [];
    return organizationData.organizations.map((o) => ({ label: o.name, value: o.id }));
  }, [organizationData]);

  const { control, handleSubmit } = useForm<ProjectForm>({
    defaultValues: {
      name: project.name,
      freeTrial: project.freeTrial,
      organization: { label: project.organization.name, value: project.organization.id },
      active: project.active,
      forecasting: project.forecasting,
      dataEntry: [JSON.stringify(project.dataEntry)],
      timezone: VALID_TIMEZONE_OPTIONS.find((tz) => tz.value === project?.timezone) ?? {
        label: 'None',
        value: ''
      },
      siwi: project.siwi,
      regionId: project.region.id,
      overviewTabs: project.tabs
        .map((pt) => {
          const tab = tabArray.find(
            (ta) => ta.serverKey === pt || ta.panes?.find((p) => p.serverKey === ta.serverKey)
          );
          const pane = tabArray.flatMap((t) => t?.panes ?? []).find((p) => p.serverKey === pt);
          if (!tab && !pane) return;
          return { label: (pane ?? tab).displayName, value: (pane ?? tab).serverKey };
        })
        .filter((t) => t)
    }
  });

  const onSubmit = async (data) => {
    console.log(data);
    toast({
      status: 'warning',
      description: 'This feature not yet implemented!'
    });
  };

  const sortedFilteredSites = useMemo(() => {
    const sorted = sortBy(project.sites, 'name');
    if (isEmpty(siteFilter)) {
      return sorted;
    } else {
      return sorted.filter((s) => s.name.toLowerCase().includes(siteFilter.toLowerCase()));
    }
  }, [project.sites, siteFilter]);

  if (!permissions.canAdminProject(user, project.id)) {
    return <Navigate to={`/project/${project.id}`} />;
  }

  return (
    <Center w="100%" mt="20px">
      <VStack w="100%">
        {user.superuser && (
          <>
            <Heading fontSize="2xl">
              Manage {project?.organization.name} - {project.name}
            </Heading>
            <Tile p="10px" w="100%" maxWidth="800px">
              <Heading fontSize="md">General Configuration</Heading>
              <Box m="20px">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <VStack spacing="5">
                    <FormSelect
                      isLoading={organizationsLoading}
                      label="Organization"
                      control={control}
                      name="organization"
                      options={organizationOptions}
                    />
                    <FormCheckbox label="Active" control={control} name="active" />
                    <FormCheckbox label="Free Trial" control={control} name="freeTrial" />
                    <FormInput label="Project Region Name" control={control} name="name" />
                    <FormSelect
                      label="Timezone"
                      control={control}
                      name="timezone"
                      options={VALID_TIMEZONE_OPTIONS}
                    />
                    <FormCheckbox label="Forecasting" control={control} name="forecasting" />
                    <FormCheckbox label="SIWI" control={control} name="siwi" />
                    <FormSelect
                      isMulti={true}
                      label="Additional Overview Tabs"
                      control={control}
                      name="overviewTabs"
                      options={[
                        ...tabArray.map((t) => ({ label: t.displayName, value: t.serverKey })),
                        ...tabArray
                          .flatMap((t) => t?.panes ?? [])
                          .map((p) => ({ label: p.displayName, value: p.serverKey }))
                      ]}
                    />
                  </VStack>
                  <Button
                    isDisabled={true}
                    float="right"
                    my="20px"
                    variant="solid"
                    type="submit"
                    colorScheme="blue">
                    Submit
                  </Button>
                </form>
              </Box>
            </Tile>
          </>
        )}
        <Spacer pt="10px" />
        <Tile w="100%" maxWidth="800px" p="10px">
          <Heading fontSize="md" my="20px">
            Site Configurations
          </Heading>
          <Input
            w="100%"
            placeholder="Site Filter..."
            value={siteFilter}
            onChange={(e) => setSiteFilter(e.currentTarget.value)}
          />
          <TableContainer>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Type</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {sortedFilteredSites.map((s) => (
                  <Tr key={s.id}>
                    <Td>{s.name}</Td>
                    <Td>{startCase(s.type)}</Td>
                    <Td>
                      <Link to={`/project/${project.id}/site/${s.id}/edit`}>
                        <IconButton
                          backgroundColor="gray.200"
                          float="right"
                          icon={<IoMdSettings />}
                          aria-label={'Edit Site Settings'}
                        />
                      </Link>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Tile>
        <Spacer py="50px" />
      </VStack>
    </Center>
  );
};

export default ProjectIdEdit;

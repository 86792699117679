import AbsoluteMortality from 'components/Charts/AbsoluteMortality/Chart';
import AverageWeight from 'components/Charts/AverageWeight/Chart';
import ChartSwitchPanel from 'components/Charts/ChartSwitchPanel';
import ChartTile from 'components/Charts/ChartTile';
import MortalityByCause, {
  ChartSettings as MortalityByCauseSettings
} from 'components/Charts/MortalityByCause/Chart';
import PercentMortality from 'components/Charts/PercentageMortality/Chart';
import TotalFish from 'components/Charts/TotalFish/Chart';
import TreatmentsCombo from 'components/Charts/Treatments/Combo';
import { ProjectContextType } from 'contexts/ProjectContext';
import { Site } from 'graphql/generated';
import { useState } from 'react';
import MortalityByCauseController from 'components/Charts/MortalityByCause/Controller';

type FishHealthProps = {
  site?: Site;
  project: ProjectContextType;
};

const FishHealth = (props: FishHealthProps) => {
  const [totalFishBiomassToggle, setTotalFishBiomassToggle] = useState(false);
  const [mortalityBiomassToggle, setMortalityBiomassToggle] = useState(false);
  const [mortalityByCauseSettings, setMortalityByCauseSettings] =
    useState<MortalityByCauseSettings>({
      showByCauseName: true,
      unit: 'count',
      project: props.project,
      selectedSublocation: 'All',
      site: props?.site
    });

  return (
    <>
      <ChartTile
        hash="absolute-mortality"
        heading="Absolute Mortality"
        site={props.site ?? null}
        control={
          <ChartSwitchPanel
            switches={[
              {
                left: { value: 'count', label: 'Count' },
                right: { value: 'biomass', label: 'Biomass' },
                switchProps: { isChecked: mortalityBiomassToggle },
                toggle: ({ checked }) => setMortalityBiomassToggle(checked)
              }
            ]}
          />
        }>
        <AbsoluteMortality
          settings={{
            site: props?.site,
            project: props.project,
            showInBiomass: mortalityBiomassToggle
          }}
        />
      </ChartTile>

      <ChartTile heading="Percentage Mortality" site={props.site}>
        <PercentMortality settings={{ project: props.project, site: props?.site }} />
      </ChartTile>

      <ChartTile hash="mortality-by-cause" heading={'Mortality by Cause'} site={props.site}>
        <MortalityByCause
          settings={mortalityByCauseSettings}
          control={({ sublocations }) => (
            <MortalityByCauseController
              controllerSettings={{ showSublocations: true }}
              chartInputs={{ sublocations }}
              setChartSettings={setMortalityByCauseSettings}
              chartSettings={mortalityByCauseSettings}
            />
          )}
        />
      </ChartTile>

      <ChartTile
        hash="total-fish"
        heading="Total Fish"
        site={props.site}
        control={
          <ChartSwitchPanel
            switches={[
              {
                left: { value: 'count', label: 'Count' },
                right: { value: 'biomass', label: 'Biomass' },
                toggle: ({ checked }) => setTotalFishBiomassToggle(checked)
              }
            ]}
          />
        }>
        <TotalFish
          settings={{
            project: props.project,
            site: props?.site,
            showInBiomass: totalFishBiomassToggle
          }}
        />
      </ChartTile>

      <ChartTile hash="average-weight" heading="Average Weight" site={props.site}>
        <AverageWeight settings={{ project: props.project, site: props?.site }} />
      </ChartTile>

      <ChartTile heading="Treatments" site={props.site}>
        <TreatmentsCombo site={props.site} project={props.project} />
      </ChartTile>
    </>
  );
};

export default FishHealth;

import { Box } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { useContext, useMemo } from 'react';
import { WiThermometer } from 'react-icons/wi';

const WaterTemperatureStat: HeadlineStat<'waterTemperature'> = ({
  project,
  site,
  ...props
}: HeadlineStatProps) => {
  const { manualHydrographyResultSet, manualHydrographyIsLoading } =
    useContext(HeadlineDataContext);

  const [tempMin, tempMax] = useMemo(() => {
    const latestData = manualHydrographyResultSet?.rawData()?.[0];
    if (latestData)
      return [
        Number(latestData['ManualHydrography.waterTempMin']?.toFixed(1)),
        Number(latestData['ManualHydrography.waterTempMax']?.toFixed(1))
      ];
    else return [null, null];
  }, [manualHydrographyResultSet]);

  return (
    <Stat
      isLoading={manualHydrographyIsLoading}
      key="water-temp"
      cyId="headline-stat"
      label="Water Temperature (&deg;C)"
      value={`${safeFormat(tempMin, 2, '')} - ${safeFormat(tempMax, 2, '')}`}
      timeLabel="Last 24 hours"
      icon={
        <Box color="blue.500">
          <WiThermometer size={30} />
        </Box>
      }
      href={`/project/${project.id}/site/${site.id}/environment#barge-hydrography`}
      {...props}
    />
  );
};

WaterTemperatureStat.statName = 'waterTemperature';
WaterTemperatureStat.requiredDatasets = ['manualHydrography'];

export default WaterTemperatureStat;

import { Box, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { IoMdTrendingDown, IoMdTrendingUp } from 'react-icons/io';
import { trafficLightPallet } from '../shared/functions/colorPallets';
import Tile, { TileProps } from './Tile';

type StatProps = {
  label: string;
  value: React.ReactChild;
  timeLabel?: string | string[];
  icon?: React.ReactChild;
  cyId?: string;
  trendUp?: boolean | undefined;
  reverseTrendColour?: boolean;
  width?: string;
  isLoading?: boolean;
} & TileProps;

const Stat = ({
  label,
  value,
  icon,
  cyId,
  href,
  timeLabel = 'Last Day',
  trendUp = undefined,
  reverseTrendColour = true,
  width = '240px',
  isLoading = false,
  ...props
}: StatProps) => {
  if (isLoading)
    return (
      <Tile w={width} m="0.5rem" borderRadius="base" boxShadow="base" {...props}>
        <Skeleton w="100%" h="95px" />
      </Tile>
    );

  return (
    <Tile href={href} w={width} m="0.5rem" borderRadius="base" boxShadow="base" {...props}>
      <HStack alignContent="center" justifyContent="space-between">
        {icon && (
          <Box m="5px" borderRadius="full" boxShadow="base" w="fit-content">
            {icon}
          </Box>
        )}
        <Text
          textAlign="center"
          pt="5px"
          fontSize={label.length > 20 ? 'sm' : 'md'}
          letterSpacing={'tighter'}
          color="gray.500"
          w={icon ? '' : '100%'}
          m={icon ? '' : '5px'}
          noOfLines={2}>
          {label}
        </Text>
        {icon && <Box w="30px" />}
      </HStack>
      <HStack justifyContent="center" alignContent="center" spacing="0">
        <Box data-cypress={cyId} fontSize="2xl" fontWeight="bold">
          {value}
        </Box>
        {trendUp !== undefined && !trendUp && (
          <Box
            padding="5px"
            color={reverseTrendColour ? trafficLightPallet[0.0] : trafficLightPallet[1.0]}>
            <IoMdTrendingDown size={20} />
          </Box>
        )}
        {trendUp !== undefined && trendUp && (
          <Box
            padding="5px"
            color={reverseTrendColour ? trafficLightPallet[1.0] : trafficLightPallet[0.0]}>
            <IoMdTrendingUp size={20} />
          </Box>
        )}
      </HStack>
      <Box>
        {Array.isArray(timeLabel) ? (
          <VStack spacing="0">
            <Text textAlign="center" fontSize="small" color="gray.500">
              {timeLabel[0]}
            </Text>
            <Text textAlign="center" fontSize="small" color="gray.500"></Text>
            <Text textAlign="center" fontSize="small" color="gray.500">
              {timeLabel[1]}
            </Text>
          </VStack>
        ) : (
          <Text textAlign="center" fontSize="small" color="gray.500">
            {timeLabel}
          </Text>
        )}
      </Box>
    </Tile>
  );
};

export default Stat;

import {
  Button,
  Divider,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Kbd,
  Tag,
  TagLabel
} from '@chakra-ui/react';
import { permissions } from '@scoot/permissions';
import AlarmsNotice from 'components/Alarm/AlarmsNotice';
import config from 'config';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import useAlarmPoller from 'hooks/useAlarmPoller';
import { useCallback, useContext, useEffect, useState } from 'react';
import { MdSend } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';
import { Link, Link as ReactLink } from 'react-router-dom';
import { SiteSearch } from './SiteSearch';
import TaskbarDropdown from './TaskbarDropdown';
import AdminMenu from './AdminMenu';
import { IoMdSettings } from 'react-icons/io';

type NavbarProps = {
  showUserEdit: () => void;
  showReleaseNotes: () => void;
};

const Navbar = ({ showUserEdit, showReleaseNotes }: NavbarProps) => {
  const userCtx = useContext(UserContext);
  const projectContext = useContext(ProjectContext);
  const alarms = useAlarmPoller();
  const [searchOpen, setSearchOpen] = useState(false);

  const determineIfYourConnor = () => navigator.appVersion.indexOf('Mac') != -1;

  // ctrl-k listener
  const handleKeyPress = useCallback((event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
      setSearchOpen(true);
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  // TODO - masquerade with new context - MEA
  // const [showStopMasq, setShowStopMasq] = useState(false);
  // useEffect(() => {
  //   setShowStopMasq(userCtx.id !== userCtx.defaultUser.id);
  // }, [userCtx.user]);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
  const narrow = useMediaQuery({ query: '(max-width: 1400px)' });

  const hasMultipleProjects = userCtx.projects.length > 1;

  const logoLink = () => (
    <ReactLink to={projectContext?.id ? `/project/${projectContext?.id}` : '/'}>
      <Image boxShadow="lg" src="/ScootScienceLogo.png" h="100%" alt="Home" />
    </ReactLink>
  );

  const projectHeader = () =>
    projectContext?.organization && projectContext?.id ? (
      narrow ? (
        projectContext.organization.name
      ) : (
        projectContext?.name + ' / ' + projectContext?.organization.name
      )
    ) : (
      <Link to="/">SeaState</Link>
    );

  const mobileNav = () => (
    <>
      <div className="db w-100 pa1 flex items-center justify-between h2 container">
        <div className="db h-100">{logoLink()}</div>
        <TaskbarDropdown showUserEdit={showUserEdit} showReleaseNotes={showReleaseNotes} />
      </div>
      <div className="db flex items-center pb4">
        <h1 className="f4 b">{projectHeader()}</h1>
        <div className="absolute db flex items-center pb2" style={{ paddingTop: '80px' }}>
          {projectContext?.id && (
            <>
              {hasMultipleProjects && (
                <div className="pr2">
                  <ReactLink to="/project/picker">
                    <Button size="md" colorScheme="blue" variant="link">
                      Project Picker
                    </Button>
                  </ReactLink>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );

  const desktopNav = () => (
    <div
      style={{
        backgroundColor: 'rgba(255,255,255,0.9)',
        zIndex: 999,
        position: 'sticky',
        padding: 25
      }}
      className="db w-100 pa1 flex items-center justify-between h2 container">
      <div className="db w-100 pa1 flex items-center justify-between h2 container">
        <div className="dib h-100">{logoLink()}</div>
        <div className="dib flex items-center">
          {projectContext?.id && permissions.canAdminProject(userCtx, projectContext.id) && (
            <ReactLink to={`/project/${projectContext.id}/edit`}>
              <IconButton
                color="blue.500"
                mr="10px"
                borderRadius="100%"
                icon={<IoMdSettings />}
                aria-label="Project Settings"
              />
            </ReactLink>
          )}
          <h1 className="f4 b">{projectHeader()}</h1>
          {projectContext?.demo && !narrow && (
            <Tag ml="10px" backgroundColor="red.100">
              <TagLabel>Demo</TagLabel>
            </Tag>
          )}
        </div>
        {projectContext?.id && (
          <InputGroup minW="200px" maxW="300px">
            <InputRightElement w="30px" mr="30px" pointerEvents="none">
              <Kbd>{determineIfYourConnor() ? 'cmd' : 'ctrl'}</Kbd> + <Kbd>k</Kbd>
            </InputRightElement>
            <Input
              onClick={() => setSearchOpen(true)}
              cursor="pointer"
              placeholder="Site Search..."
            />
          </InputGroup>
        )}
        <HStack divider={<Divider borderColor="gray.400" orientation="vertical" height="20px" />}>
          {projectContext?.id && hasMultipleProjects && (
            <ReactLink to="/project/picker">
              <Button size="md" colorScheme="blue" variant="link">
                Project Picker
              </Button>
            </ReactLink>
          )}
          {projectContext?.id && permissions.canAdminProject(userCtx, projectContext.id) && (
            <AdminMenu projectId={projectContext.id} />
          )}
          {projectContext?.id && (
            <AlarmsNotice
              alarms={alarms.data}
              previousAlarms={alarms?.previousData}
              error={!!alarms.error}
              loading={alarms.loading}
            />
          )}
          <TaskbarDropdown showUserEdit={showUserEdit} showReleaseNotes={showReleaseNotes} />
          {!narrow && (
            <Button
              leftIcon={<MdSend style={{ marginBottom: '4px' }} />}
              variant="link"
              as="a"
              href={config.ASANA_FEEDBACK_FORM_URL}>
              Give us Feedback!
            </Button>
          )}
        </HStack>
      </div>
      {searchOpen && <SiteSearch isOpen={searchOpen} onClose={() => setSearchOpen(false)} />}
    </div>
  );

  return <>{isTabletOrMobile ? mobileNav() : desktopNav()}</>;
};

export { Navbar };

import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { merge, uniq } from 'lodash';
import { PlotlyDataLayoutConfig } from 'plotly.js';
import { useEffect, useState } from 'react';
import { paramOptions } from 'shared/Config';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import addOxygenSatShapes from '../addOxygenSatShapes';
import useConditionData, {
  HiResHydrographyStructure,
  plotNameToConfigParam
} from './useConditionData';
import { BaseChartProps } from '../types';
import FishLoader from 'components/Loaders/FishLoader';

type ConditionsNowStructure = {
  [sensorType: string]: {
    // Includes barge at depth. ex. "barge-15m"
    [sublocation: string]: ConditionsNowDatum;
  };
};

type ConditionsNowDatum = {
  value: number;
  measuredAt: string;
};

const Now = ({ settings, skip }: BaseChartProps) => {
  const { isLoading, error, data } = useConditionData({
    settings,
    granularity: 'minute',
    dateRange: 'last 1440 minutes',
    skip,
    refreshInterval: 90000
  });
  const [plot, setPlot] = useState<PlotlyDataLayoutConfig>(null);

  const transform = (data: HiResHydrographyStructure): ConditionsNowStructure => {
    return Object.fromEntries(
      Object.entries(data).map(([sensor, sublocationData]) => {
        const sublocationEntries = Object.entries(sublocationData);
        let mostRecentEntries = sublocationEntries.map((entry) => {
          const mostRecent = entry[1].find((e) => e.value);

          if (!mostRecent) return [];

          return [entry[0], { measuredAt: mostRecent.time, value: mostRecent.value }];
        });

        mostRecentEntries = mostRecentEntries
          .filter((e) => e.length !== 0)
          .sort((a, b) => locationToIndex(a[0]) - locationToIndex(b[0]));

        const latest = Object.fromEntries(mostRecentEntries);

        return [sensor, latest];
      })
    );
  };

  const isDataStale = (measuredAt: Date, timezone: string) => {
    const utcMeasured = zonedTimeToUtc(measuredAt, timezone);
    const anHourAgo = new Date(Date.now() - 1000 * 60 * 60);
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const hourAgoUtc = zonedTimeToUtc(anHourAgo, localTimezone);

    return utcMeasured < hourAgoUtc;
  };

  const createYAxisAnnotation = (label: string, idx: number, total: number) => {
    return {
      xref: 'paper',
      x: 1.05,
      xanchor: 'left',
      y: 1 - idx / total,
      yanchor: 'middle',
      yref: 'paper',
      text: label,
      showarrow: false,
      font: {
        size: 14
      },
      textangle: 90,
      xshift: 5,
      yshift: 120 - (idx / total) * 20
    };
  };

  const graph = (data: ConditionsNowStructure) => {
    const annotations = [];
    const subLayouts = {};
    const locations = uniq(
      Object.values(data).flatMap((d) =>
        settings.site?.smbId ? Object.keys(d) : Object.keys(d).map((loc) => loc.split('-')[0])
      )
    );
    const pallet = createLocationPallet({ locations });
    // Need to know all possible sublocations (containers) in case somke sensors are missing some sublocations;
    // performing this check and filling in missing sublocations for those sensors with null data is needed for the legend
    // to render correctly (including all sublocations in the right order)
    // const allContainers = Object.keys(data);
    const allContainers = uniq(
      Object.values(data).flatMap((sensorData) => {
        return Object.keys(sensorData);
      })
    );

    const validSensorData = Object.entries(data).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_sensor, sublocationData]) =>
        !Object.values(sublocationData).every((sd) => sd.value === null)
    );

    const plotData = validSensorData.map(([sensor, sublocationData], idx) => {
      const paramOption = paramOptions[plotNameToConfigParam[sensor]];
      allContainers.map((container: string) => {
        if (!Object.keys(sublocationData).includes(container.toString())) {
          if (Object.values(sublocationData).length > 0) {
            const dummyData = Object.assign({}, Object.values(sublocationData)[0]);
            sublocationData[container] = dummyData;
            sublocationData[container]['value'] = undefined;
          }
        }
      });
      //I hate to have to leak this, but it's the easiest way atm.
      annotations.push(createYAxisAnnotation(paramOption.label, idx + 1, Object.keys(data).length));
      subLayouts[`yaxis${idx + 1}`] = {
        side: 'right'
      };
      const sortedSublocationData = Object.entries(sublocationData).sort(
        // eslint-disable-next-line
        ([sublocationA, _sublocationAData], [sublocationB, _sublocationBData]) =>
          locationToIndex(sublocationA) - locationToIndex(sublocationB)
      );

      const xVals = sortedSublocationData.map((d) => d[0]);
      const yVals = sortedSublocationData.map((d) => d[1].value);
      return {
        type: 'bar',
        orientation: 'v',
        y: yVals,
        x: settings.site?.smbId
          ? xVals
          : xVals?.map(
              (d) =>
                settings.project.siteNameMappings[d.split('-')[0].replace('*', '')]?.slice(0, 3) +
                ' ' +
                d.split('-')?.slice(1)
            ),
        name: settings.site?.smbId
          ? xVals
          : xVals.map(
              (d) =>
                settings.project.siteNameMappings[d.split('-')[0].replace('*', '')] +
                ' ' +
                d.split('-').slice(1)
            ),
        yaxis: `y${idx + 1}`,
        texttemplate: yVals.length > 0 ? '%{y:.2f}' : undefined,
        legendgroup: settings.site?.smbId
          ? xVals
          : xVals.map((d) => settings.project.siteNameMappings[d.split('-')[0]]),
        hovertemplate: sortedSublocationData.map(([location, locationData]) => {
          return (
            `<b>${paramOption.name}: %{y:.2f} ${paramOption.units} </b></br>` +
            `<b>Time: ${format(new Date(locationData.measuredAt), 'iii MMM dd yyyy HH:mm')} ${
              settings.project.timezoneLabel
            }</b></br>` +
            `<b>${
              settings.site?.smbId
                ? location
                : [
                    settings.project.siteNameMappings[location.split('-')[0].replace('*', '')],
                    location.split('-').slice(1)
                  ].join(' ')
            }</b><br>` +
            '<extra></extra>'
          );
        }),
        textposition: Object.values(sublocationData).flatMap((x) => {
          if (x.value === 0) {
            return 'outside';
          } else {
            return 'auto';
          }
        }),
        textangle: 270,
        marker: {
          color: xVals.map((sublocation) => {
            return settings.site?.smbId ? pallet[sublocation] : pallet[sublocation.split('-')[0]];
          }),
          opacity: Object.values(sublocationData).map((d) =>
            isDataStale(new Date(d.measuredAt), settings.project.timezone) ? 0.3 : 1
          ),
          line: {
            color: Object.values(sublocationData).map((d) =>
              isDataStale(new Date(d.measuredAt), settings.project.timezone) ? 'black' : null
            ),
            width: Object.values(sublocationData).map((d) =>
              isDataStale(new Date(d.measuredAt), settings.project.timezone) ? 5 : 0
            )
          }
        },
        showlegend: false
      };
    });

    const layout = {
      grid: {
        rows: validSensorData.length,
        columns: 1
      },
      margin: { t: 40, b: 60, l: 20 },
      autosize: true,
      height: settings.site?.smbId ? 300 * validSensorData.length : 400 * validSensorData.length,
      clickmode: 'event',
      hovermode: 'x',
      hoverlabel: {
        align: 'left'
      },
      xaxis: {
        tickangle: 315
      },
      annotations
    };

    const plot = {
      data: plotData,
      layout: merge(layout, subLayouts)
    };

    if (plotData.length > 0) {
      //@ts-ignore
      const plotXs = plotData[0].x;
      //@ts-ignore
      addOxygenSatShapes('y', [plotXs[0], plotXs[plotXs.length - 1]], plot, settings.project);
    }

    return plot;
  };

  useEffect(() => {
    if (!data) return;
    const transformed = transform(data);
    //@ts-ignore
    setPlot(graph(transformed));
  }, [data]);

  if (isLoading) {
    return <FishLoader minH="600" height="100%" width={'100%'} />;
  }

  if (error) {
    return <GraphError minH="600px" />;
  }

  return plot?.data.length ? (
    <Plot className="w-100 conditions-now-plot" useResizeHandler={true} {...plot} />
  ) : plot?.data?.length === 0 && settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <NoData minH="600px" />
  );
};

export default Now;

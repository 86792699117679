import { Box } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { useContext, useMemo } from 'react';
import { TbSalt } from 'react-icons/tb';

const SalinityPsuStat: HeadlineStat<'salinity'> = ({
  project,
  site,
  ...props
}: HeadlineStatProps) => {
  const { manualHydrographyResultSet, manualHydrographyIsLoading } =
    useContext(HeadlineDataContext);

  const [salinityMin, salinityMax] = useMemo(() => {
    const latestData = manualHydrographyResultSet?.rawData()?.[0];
    if (latestData)
      return [
        Number(latestData['ManualHydrography.waterTempMin']?.toFixed(1)),
        Number(latestData['ManualHydrography.waterTempMax']?.toFixed(1))
      ];
    else return [null, null];
  }, [manualHydrographyResultSet]);

  return (
    <Stat
      isLoading={manualHydrographyIsLoading}
      key="salinity-psu"
      cyId="headline-stat"
      label="Salinity (psu)"
      timeLabel="Last 24 hours"
      value={`${safeFormat(salinityMin, 2, '')} - ${safeFormat(salinityMax, 2, '')}`}
      icon={
        <Box padding="5px" color="blue.500">
          <TbSalt size={20} />
        </Box>
      }
      href={`/project/${project.id}/site/${site.id}/environment#barge-hydrography`}
      {...props}
    />
  );
};

SalinityPsuStat.statName = 'salinity';
SalinityPsuStat.requiredDatasets = ['manualHydrography'];

export default SalinityPsuStat;

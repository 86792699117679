import { Box } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { useContext, useMemo } from 'react';
import { GiBubbles } from 'react-icons/gi';

const OxygenPercentStat: HeadlineStat<'oxygenPercent'> = ({
  project,
  site,
  ...props
}: HeadlineStatProps) => {
  const { manualHydrographyResultSet, manualHydrographyIsLoading } =
    useContext(HeadlineDataContext);

  const [oxMin, oxMax] = useMemo(() => {
    const latestData = manualHydrographyResultSet?.rawData()?.[0];
    if (latestData)
      return [
        Number(latestData['ManualHydrography.oxygenSaturationMin']?.toFixed(1)),
        Number(latestData['ManualHydrography.oxygenSaturationMax']?.toFixed(1))
      ];
    else return [null, null];
  }, [manualHydrographyResultSet]);

  return (
    <Stat
      isLoading={manualHydrographyIsLoading}
      key="oxygen-percent"
      cyId="headline-stat"
      label="Oxygen (%)"
      value={`${safeFormat(oxMin, 2, '')} - ${safeFormat(oxMax, 2, '')}`}
      timeLabel="Last 24 hours"
      icon={
        <Box padding="5px" color="blue.500">
          <GiBubbles size={20} />
        </Box>
      }
      href={`/project/${project.id}/site/${site.id}/environment#barge-hydrography`}
      {...props}
    />
  );
};

OxygenPercentStat.statName = 'oxygenPercent';
OxygenPercentStat.requiredDatasets = ['manualHydrography'];

export default OxygenPercentStat;
